var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.bookingData.ref, items: _vm.items }
      }),
      !_vm.bookingData.checkout && _vm.bookingData.statusPlain != "rejected"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  !_vm.bookingData.checkout
                    ? _c(
                        "div",
                        { staticClass: "button-items" },
                        [
                          _vm.bookingData.statusPlain == "draft" &&
                          _vm.$can("update_hosting_booking")
                            ? _c(
                                "b-button",
                                { attrs: { variant: "primary" } },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-edit font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Modifier ")
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingData.statusPlain == "draft" &&
                          _vm.$can("approve_booking")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.approvalModal",
                                      modifiers: { approvalModal: true }
                                    }
                                  ],
                                  attrs: { variant: "success" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-double font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Approuver ")
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingData.statusPlain == "approved" &&
                          !_vm.bookingData.checkin &&
                          _vm.$can("checkin_booking")
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: {
                                    click: function($event) {
                                      _vm.showCheckInModal = true
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-square font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Check IN ")
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingData.statusPlain == "approved" &&
                          _vm.bookingData.checkin &&
                          !_vm.bookingData.checkout &&
                          _vm.$can("checkout_booking")
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "warning" },
                                  on: {
                                    click: function($event) {
                                      _vm.showCheckOutModal = true
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-exit font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Check OUT ")
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingData.statusPlain == "draft" &&
                          _vm.$can("reject_booking")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.rejectionModal",
                                      modifiers: { rejectionModal: true }
                                    }
                                  ],
                                  attrs: { variant: "danger" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-block font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Rejeter ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(" Détails de réservation N°: "),
                  _c("b", [_vm._v(_vm._s(_vm.bookingData.ref))])
                ]),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void()" },
                    on: {
                      click: function($event) {
                        return _vm.fetchSinglebookingData()
                      }
                    }
                  },
                  [
                    _c("h1", [
                      _c("i", {
                        staticClass: "bx bx-rotate-right",
                        staticStyle: { float: "right" }
                      })
                    ])
                  ]
                ),
                _c("p", { staticClass: "card-title-desc" }),
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Informations")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "table-responsive mb-0" },
                              [
                                _c("table", { staticClass: "table" }, [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Occupant:")]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.bookingData.occupantFullName
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Date d'arrivé:")]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.bookingData.planned_arrival_date
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Date de départ:")]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.bookingData
                                                .planned_departure_date
                                            ) +
                                            " / " +
                                            _vm._s(_vm.bookingData.nights) +
                                            " Nuitées. "
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v("Réservation créer par:")
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.bookingData.createdBy)
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Date de création:")]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.bookingData.createdAt)
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "table-responsive mb-0" },
                              [
                                _c("table", { staticClass: "table" }, [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Chambre :")]),
                                      _c("td", [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.bookingData.room.type)
                                          )
                                        ]),
                                        _vm._v(" N°: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.bookingData.room.number)
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Etat :")]),
                                      _c("td", [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.bookingData.status
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.bookingData.checkin
                                          ? _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge rounded-pill bg-success ms-1"
                                                },
                                                [_vm._v("CHECKIN")]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.bookingData.checkout
                                          ? _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge rounded-pill bg-warning ms-1"
                                                },
                                                [_vm._v("CHECKOUT")]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span")
                                      ])
                                    ]),
                                    _vm.bookingData.statusPlain == "rejected"
                                      ? _c("tr", [
                                          _c("th", [_vm._v("Rejeté par :")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.bookingData.rejectedBy)
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.bookingData.statusPlain == "rejected"
                                      ? _c("tr", [
                                          _c("th", [_vm._v("Date de rejet:")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.rejectionDate
                                              )
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.bookingData.statusPlain == "rejected"
                                      ? _c("tr", [
                                          _c("th", [
                                            _vm._v("Raison de rejet:")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.rejectionReason
                                              )
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.bookingData.statusPlain == "approved"
                                      ? _c("tr", [
                                          _c("th", [_vm._v("Approuvée par :")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.bookingData.approvedBy)
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.bookingData.statusPlain == "approved"
                                      ? _c("tr", [
                                          _c("th", [
                                            _vm._v("Date d'approbation :")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.bookingData.approvedAt)
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm.bookingData.checkin
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-inline-block d-sm-none"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-user"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-sm-inline-block"
                                        },
                                        [_vm._v("CHECK-IN")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2284203105
                            )
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-responsive mb-0" },
                                  [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Date:")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.bookingData.checkin_at)
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Opération par::")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.checkedInBy
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Commentaires:")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.bookingData
                                                    .checkInComments
                                                ) +
                                                " "
                                            )
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.bookingData.checkout
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-inline-block d-sm-none"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-envelope"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-sm-inline-block"
                                        },
                                        [_vm._v("CHECK-OUT")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1768879479
                            )
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-responsive mb-0" },
                                  [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Date:")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.checkout_at
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Opération par::")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.checkedOutBy
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Commentaires:")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.bookingData
                                                    .checkOutComments
                                                ) +
                                                " "
                                            )
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: { title: "CHECK IN" },
          model: {
            value: _vm.showCheckInModal,
            callback: function($$v) {
              _vm.showCheckInModal = $$v
            },
            expression: "showCheckInModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.processCheckIn($event)
                }
              }
            },
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v("Les champs obligatoires sont marqués avec (*) ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Date / Heure *")
                      ]),
                      _c("date-picker", {
                        class: {
                          "is-invalid":
                            _vm.submitted &&
                            _vm.$v.checkInForm.checkInDate.$error
                        },
                        attrs: { config: _vm.dtpConfig },
                        model: {
                          value: _vm.checkInForm.checkInDate,
                          callback: function($$v) {
                            _vm.$set(_vm.checkInForm, "checkInDate", $$v)
                          },
                          expression: "checkInForm.checkInDate"
                        }
                      }),
                      _vm.submitted && _vm.$v.checkInForm.checkInDate.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.checkInForm.checkInDate.required
                              ? _c("span", [_vm._v("La date est obligatoire.")])
                              : _vm._e(),
                            _c("br")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Commentaires")]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkInForm.comments,
                          expression: "checkInForm.comments"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "5" },
                      domProps: { value: _vm.checkInForm.comments },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.checkInForm,
                            "comments",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" Enregistrer ")]
                )
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "CHECK OUT" },
          model: {
            value: _vm.showCheckOutModal,
            callback: function($$v) {
              _vm.showCheckOutModal = $$v
            },
            expression: "showCheckOutModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.processCheckOut($event)
                }
              }
            },
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v("Les champs obligatoires sont marqués avec (*) ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Date / Heure *")
                      ]),
                      _c("date-picker", {
                        class: {
                          "is-invalid":
                            _vm.submitted &&
                            _vm.$v.checkOutForm.checkOutDate.$error
                        },
                        attrs: { config: _vm.dtpConfig },
                        model: {
                          value: _vm.checkOutForm.checkOutDate,
                          callback: function($$v) {
                            _vm.$set(_vm.checkOutForm, "checkOutDate", $$v)
                          },
                          expression: "checkOutForm.checkOutDate"
                        }
                      }),
                      _vm.submitted && _vm.$v.checkOutForm.checkOutDate.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.checkOutForm.checkOutDate.required
                              ? _c("span", [_vm._v("La date est obligatoire.")])
                              : _vm._e(),
                            _c("br")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Commentaires")]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkInForm.comments,
                          expression: "checkInForm.comments"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "5" },
                      domProps: { value: _vm.checkInForm.comments },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.checkInForm,
                            "comments",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" Enregistrer ")]
                )
              ])
            ],
            1
          )
        ]
      ),
      _c("approval-modal", {
        attrs: {
          modelRef: _vm.bookingData.ref,
          modelId: _vm.bookingData.id,
          modelType: _vm.bookingData.modelType
        }
      }),
      _c("rejection-modal", {
        attrs: {
          modelRef: _vm.bookingData.ref,
          modelId: _vm.bookingData.id,
          modelType: _vm.bookingData.modelType
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }